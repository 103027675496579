// src/components/LoadingScreen.tsx
import React from 'react';

const LoadingScreen: React.FC = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      backgroundColor: '#ffffff',
      zIndex: 9999,
    }}
  >
    <div>Loading...</div>
  </div>
);

export default LoadingScreen;
