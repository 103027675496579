// adminDashboard.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import UserEditForm from './userEditForm';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth

const ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_ENDPOINT || ''
    : process.env.REACT_APP_DEV_ENDPOINT || '';

const axiosInstance = axios.create({
  baseURL: ENDPOINT,
  withCredentials: true, // This ensures cookies are sent with requests
});

interface User {
  id: string;
  username: string;
  email: string;
  role: string;
}

const AdminDashboard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const { user, isAuthenticated, isAdmin } = useAuth(); // Use the AuthContext

  useEffect(() => {
    const fetchUsers = async () => {
      if (!isAuthenticated || !isAdmin) {
        navigate('/login');
        return;
      }
      try {
        const response = await axiosInstance.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle error (e.g., redirect to login if unauthorized)
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, [isAuthenticated, isAdmin, navigate]);

  const handleEditUser = (user: User): void => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this user?'
      );
      if (confirmDelete) {
        await axiosInstance.delete(`/api/${userId}`);
        setUsers(users.filter((user) => user.id !== userId));
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleSave = async (editedUser: User) => {
    try {
      const { id, username, email, role } = editedUser;
      const response = await axiosInstance.put(`/api/users/${id}`, {
        name: username,
        email,
        role,
      });

      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === editedUser.id ? editedUser : user
          )
        );
        setModalOpen(false);
      } else {
        console.error('Error updating user:', response.status);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>Access Denied</div>;
  }

  return (
    <div className="container">
      <h2 className="mb-4">Admin Dashboard</h2>
      {user && (
        <div>
          <p>Welcome, {user.username}!</p>
        </div>
      )}
      <h3 className="mt-4">User List</h3>
      <div className="list-group">
        {users.map((user) => (
          <div
            key={user.id}
            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
          >
            <div>
              {user.username} - {user.email} - {user.role}
            </div>
            <div>
              <button
                className="btn btn-primary btn-sm me-2"
                onClick={() => handleEditUser(user)}
              >
                Edit
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => handleDeleteUser(user.id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        {selectedUser && (
          <UserEditForm user={selectedUser} onSave={handleSave} />
        )}
      </Modal>
    </div>
  );
};

export default AdminDashboard;
