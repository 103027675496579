// loginForm.tsx
import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Footer from './Footer';
import './Footer2.css';
import { motion } from 'framer-motion';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';

interface LoginErrorResponse {
  error: string;
}

const ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_ENDPOINT || ''
    : process.env.REACT_APP_DEV_ENDPOINT || '';

const axiosInstance = axios.create({
  baseURL: ENDPOINT,
  withCredentials: true,
});

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const { login, googleLogin } = useAuth(); // Add googleLogin from useAuth

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await login(username, password);
      setSuccess('Login successful!');
      setError('');
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error: any) {
      console.error('Login failed:', error);
      if (axios.isAxiosError(error) && error.response) {
        const serverError = error.response.data as { error: string };
        if (serverError && serverError.error) {
          setError(serverError.error);
        } else {
          setError('An unexpected server error occurred');
        }
      } else {
        setError('An unknown error occurred');
      }
      setSuccess('');
    }
  };
  const handleGoogleSuccess = async (
    credentialResponse: CredentialResponse
  ) => {
    try {
      if (!credentialResponse.credential) {
        throw new Error('No credential received from Google');
      }
      await googleLogin(credentialResponse.credential);
      setSuccess('Google login successful!');
      setError('');
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      handleGoogleLoginError(error);
    }
  };

  const handleGoogleError = () => {
    setError('Google Sign-In process failed. Please try again.');
    setSuccess('');
  };

  const handleGoogleLoginError = (error: unknown) => {
    console.error('Google login failed:', error);

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const statusCode = axiosError.response.status;
        let errorMessage = 'Unknown error occurred';

        if (typeof axiosError.response.data === 'string') {
          errorMessage = axiosError.response.data;
        } else if (
          typeof axiosError.response.data === 'object' &&
          axiosError.response.data !== null
        ) {
          errorMessage =
            (axiosError.response.data as any).error ||
            JSON.stringify(axiosError.response.data);
        }

        setError(`Server error (${statusCode}): ${errorMessage}`);
        console.error('Response data:', axiosError.response.data);
        console.error('Response status:', axiosError.response.status);
        console.error('Response headers:', axiosError.response.headers);
      } else if (axiosError.request) {
        setError(
          'No response received from the server. Please try again later.'
        );
      } else {
        setError(`Request error: ${axiosError.message}`);
      }
    } else if (error instanceof Error) {
      setError(`Error: ${error.message}`);
    } else {
      setError(
        'An unexpected error occurred during Google login. Please try again.'
      );
    }

    setSuccess('');
  };
  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="row">
        <div className="col-sm-12">
          <motion.div
            className="card"
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            transition={{ type: 'spring', stiffness: 120, damping: 20 }}
          >
            <div className="card-body">
              <div className="g-signin2" data-onsuccess="onSignIn"></div>

              <h2 className="card-title text-center">Login</h2>
              {success && (
                <div className="alert alert-success" role="alert">
                  {success}
                </div>
              )}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <fieldset>
                  <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                      type="text"
                      id="username"
                      className="form-control"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Login
                  </button>
                </fieldset>
              </form>
              <div className="text-center mt-3">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                />
                <Link to="/password-request">Forgot Password?</Link>
              </div>
              <div className="text-center mt-3">
                <Link to="/signup">Sign Up</Link>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      <Footer className="footer2" />
    </div>
  );
};

export default LoginForm;
